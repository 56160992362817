import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import TrackPageTableGroupHeader from "../../../../components/track-page-table-group-header";
import PaginatedListTableWithTypes, {
  getValueByType,
  Header
} from "../../../../../../components/paginated-list-table-with-types";
import useAssetTrackPageDataSourceContext from "../../../../contexts/asset-track-page-data-source-context";
import useAssetTrackPageStateContext from "../../../../contexts/asset-track-page-state-context";
import useDebounce from "../../../../../../hooks/use-debounce";
import Style from "./GroupedAssetListTable.module.css";

interface GroupedAssetListTableProps {
  headers: Header[];
  groups: {
    id: string;
    groupFields?: {
      label?: string;
      value?: string | number;
    }[];
    dataList: {
      id: string;
      isChecked?: boolean;
      [key: string]: string | number | boolean | undefined;
    }[];
  }[];
  pageSize?: number;
  selectable?: boolean;
  onSelect?: () => void;
  isDataLoading?: boolean;
}

export const GroupedAssetListTable = ({
  headers,
  pageSize = 10,
  groups,
  selectable = false,
  onSelect = () => {},
  isDataLoading = false
}: GroupedAssetListTableProps) => {
  const history = useHistory();
  const { locationTreeMap, locationToMetricFieldsMap, locationMetricsLoading } = useAssetTrackPageDataSourceContext();
  const {
    expandedLocations,
    locationSortOrder,
    selectedLocationId,
    setExpandedLocations,
    setSelectedLocationId,
    setDisplayedData,
    applySideFilterFn,
    searchInput
  } = useAssetTrackPageStateContext();

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const searchInputTextDebounced = useDebounce(searchInput, 250);

  const filterStringList = useMemo(() => {
    return searchInputTextDebounced
      .toLowerCase()
      .split(" ")
      .filter((eachString: string) => {
        return eachString;
      });
  }, [searchInputTextDebounced]);

  const filteredGroups = useMemo(() => {
    let filterApplied = false;
    const filtered = groups
      .map((group) => {
        const filteredDataList = group.dataList.filter((item) => {
          // Filter results by side filters
          if (!applySideFilterFn(item)) {
            return false;
          }

          if (filterStringList.length === 0) {
            return true;
          }

          // Filter results by search input
          const displayString = headers.reduce((accumulator, eachHeader) => {
            return accumulator + getValueByType(item[eachHeader.id], eachHeader, true);
          }, "");

          return filterStringList.some((eachFilterString: string) => {
            return displayString.toString().toLowerCase().includes(eachFilterString);
          });
        });

        filterApplied = filterApplied || filteredDataList.length !== group.dataList.length;

        return { ...group, dataList: filteredDataList };
      })
      .filter((group) => {
        return !filterApplied || group.dataList.length > 0;
      });

    setIsFilterApplied(filterApplied);
    return filtered;
  }, [groups, filterStringList, applySideFilterFn]);

  const sortedFilteredGroups = useMemo(() => {
    const sortedFiltered = filteredGroups.sort((a, b) => {
      const titleA = locationTreeMap[a.id].name;
      const titleB = locationTreeMap[b.id].name;
      switch (locationSortOrder) {
        case "asc":
          return titleA.localeCompare(titleB);
        case "desc":
          return titleB.localeCompare(titleA);
        default:
          if (locationMetricsLoading) {
            return titleB.localeCompare(titleA);
          }
          const fieldA =
            locationToMetricFieldsMap[a.id].find((field: { id: string }) => {
              return field.id === locationSortOrder;
            })?.value || 0;
          const fieldB =
            locationToMetricFieldsMap[b.id].find((field: { id: string }) => {
              return field.id === locationSortOrder;
            })?.value || 0;

          return fieldB - fieldA;
      }
    });

    return sortedFiltered;
  }, [locationToMetricFieldsMap, filteredGroups, locationSortOrder]);

  useEffect(() => {
    if (sortedFilteredGroups.length === 1) {
      setExpandedLocations({
        [sortedFilteredGroups[0].id]: true
      });
    }

    setDisplayedData(sortedFilteredGroups);
  }, [sortedFilteredGroups]);

  return (
    <div className={Style.groups_container}>
      {sortedFilteredGroups.map((group) => {
        const { id, dataList } = group;
        return (
          <div key={id}>
            <TrackPageTableGroupHeader
              groupTitle={locationTreeMap[id].name}
              groupFields={isFilterApplied ? [] : locationToMetricFieldsMap[id]}
              fieldsLoading={locationMetricsLoading}
              isGroupClickable={id !== selectedLocationId}
              onGroupClick={() => {
                setSelectedLocationId(id);
              }}
              isExpanded={expandedLocations[id]}
              onExpandClick={(isExpanded) => {
                setExpandedLocations({ ...expandedLocations, [id]: isExpanded });
              }}
            />
            {expandedLocations[id] && (
              <div
                data-cy={`list-table-${id}`}
                className={Style.table_container}
              >
                <PaginatedListTableWithTypes
                  isLoading={isDataLoading && dataList.length === 0}
                  headers={headers}
                  dataList={dataList}
                  getTitleURLCallbackFn={(data) => {
                    return `${history.location.pathname}/detail?itemId=${data.id}`;
                  }}
                  selectable={selectable}
                  onSelectClick={onSelect}
                  numItemsPerPage={pageSize}
                  selectedRowClassName={Style.selected_table_item}
                  paginatorLocation={dataList.length > 0 ? "top" : "none"}
                  paginatorFontWeight={700}
                  showRowsPerPage
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
