import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { generateTag } from "../../../../../../../../../../utils/generate-tags";

export const createContainer = async (newContainer, containerTypeIdentifier = "Pallet") => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();
  const itemClient = XemelgoService.getClient().getItemClient();

  const tag = newContainer?.vid || generateTag("Container");

  const createPayload = {
    item_number: containerTypeIdentifier,
    item_identifier: newContainer?.identifier,
    class: "Container",
    tracker_serial: tag
  };

  const { createItemSet } = await inventoryClient.createItemSet([createPayload]);
  return (await itemClient.getItemsBySensorProfileVids(createItemSet))[0];
};
