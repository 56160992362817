import React, { useContext, useMemo } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import xemelgoStyle from "../../../../styles/variable";
import { READ_MODE_OPTIONS } from "../../../../hooks/use-mounted-reader/data/constants";
import { ACTION_OPTIONS_MAP } from "../../data/constants";
import { AddPageInputTypeMap } from "../../../../data/constants";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";
import { DEFAULT_EPC_TABLE_HEADERS } from "../../features/selected-kiosk-feature/features/transfer-order-actions/data/constants";

const configKeys = {
  TITLE: "title",
  ACTIONS_MAP: "actionsMap",
  TABLE_SECTIONS: "tableSections",
  TOP_PANEL_ATTRIBUTES: "topPanelAttributes",
  SIDE_PANEL_ATTRIBUTES: "sidePanelAttributes",
  EDGE_API_URL: "edgeApiUrl",
  ADDITIONAL_QUERY_ATTRIBUTES: "additionalQueryAttributes",
  ITEM_TYPE_QUERY_ATTRIBUTES: "itemTypeQueryAttributes",
  REPORT: "report",
  UPC_NUMBER_OF_CHARACTER_TO_TRIM: "upcNumberOfCharactersToTrim",
  INACTIVITY_THRESHOLD_IN_MINUTES: "inactivityThresholdInMinutes",
  HAS_SGTIN_TAGS: "hasSgtinTags",
  ENABLED_SIMPLE_TAG_GROUPING: "enabledSimpleTagGrouping",
  ROW_BACKGROUND_COLOR: "rowBackgroundColor",
  UPDATE_READER_STATE_ON_CLEAR: "updateReaderStateOnClear",
  TABLE_TEXT_STYLE: "tableTextStyle",
  ITEM_CLASS_FILTERS: "itemClassFilters",
  DEFAULT_LOCATION_IDENTIFIER_FILTER_BY: "defaultLocationIdentifierFilterBy",
  LOCATION_OPTION_CATEGORY: "locationOptionCategory",
  READER_LOCATION_OPTION_CATEGORY: "readerLocationOptionCategory",
  AUTO_START_SCANNING: "autoStartScanning",
  EPC_TABLE_HEADERS: "epcTableHeaders",
  PRINT_OPTIONS: "printOptions",
  READER_OPTIONS: "readerOptions",
  SKIP_SUBMISSION: "skipSubmission"
};

const KioskConfigContext = React.createContext();

const SOLUTION_ID = "kiosk";

const initialState = {
  [ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION]: {
    tableSections: [
      {
        attributes: [
          {
            id: "rowCount"
          }
        ],
        label: "#",
        size: "xsmall"
      },
      {
        attributes: [
          {
            id: "image_path",
            label: "",
            type: "image"
          }
        ],
        label: "Item Photo"
      },
      {
        attributes: [
          {
            id: "name"
          },
          {
            id: "identifier",
            label: "UPC"
          },
          {
            id: "showEpcsButton"
          }
        ],
        label: "Item Description",
        size: "small"
      },
      {
        attributes: [
          {
            id: "quantityTotal"
          }
        ],
        label: "Expected"
      },
      {
        attributes: [
          {
            id: "quantityScanned"
          }
        ],
        label: "Scanned"
      },
      {
        attributes: [
          {
            id: "status"
          }
        ],
        label: "Status"
      }
    ],
    topPanelAttributes: [
      {
        id: "stage",
        label: "Stage:",
        isReadOnly: true
      },
      {
        id: "transferOrderIdentifiersString",
        label: "Tracking #:",
        isReadOnly: true
      },
      {
        id: "readerLocation",
        label: "Read Point:",
        type: AddPageInputTypeMap.SEARCH_DROP_DOWN,
        isRequired: true
      }
    ]
  },
  [ACTION_OPTIONS_MAP.ORDER_CREATION]: {
    topPanelAttributes: [
      {
        id: "transferOrderIdentifiersString",
        label: "Tracking #:",
        isReadOnly: true
      },
      {
        id: "readerLocation",
        label: "Read Point:",
        type: AddPageInputTypeMap.SEARCH_DROP_DOWN,
        isRequired: true
      }
    ]
  },
  title: "Kiosk",
  actionsMap: {
    "check-out": {
      id: "check-out",
      label: "Start Checking Out"
    }
  },
  tableSections: [
    {
      attributes: [
        {
          id: "rowCount",
          label: ""
        }
      ],
      label: "#",
      size: "xsmall"
    },
    {
      attributes: [
        {
          id: "image_path",
          label: "",
          type: "image"
        }
      ],
      label: "Item Photo"
    },
    {
      attributes: [
        {
          id: "identifier",
          label: ""
        },
        {
          id: "showEpcsButton"
        }
      ],
      label: "UPC"
    },
    {
      attributes: [
        {
          id: "quantityScanned"
        }
      ],
      label: "Qty"
    }
  ],
  topPanelAttributes: [
    {
      id: "location",
      label: "Location:",
      type: "dropdownWithOptionsFromAPI",
      isRequired: true
    },
    {
      id: "readerLocation",
      label: "Read Point:",
      type: "dropdownWithOptionsFromAPI",
      isRequired: true
    }
  ],
  sidePanelAttributes: [],
  defaultLocationIdentifierFilterBy: "",
  report: {
    additionalSubmitAttributesMap: {},
    customSubmitActionsMap: {
      endTrackingSession: false,
      consumeItem: true,
      flipHasExitState: true
    }
  },
  upcNumberOfCharactersToTrim: 2,
  inactivityThresholdInMinutes: 30,
  hasSgtinTags: false,
  enableSimpleTagGrouping: false,
  rowBackgroundColor: "transparent",
  updateReaderStateOnClear: true,
  tableTextStyle: {
    fontFamily: `"Avenir", sans-serif`,
    fontWeight: 700,
    fontSize: 15,
    color: xemelgoStyle.theme.TEXT_PRIMARY,
    backgroundColor: xemelgoStyle.theme.APP_WHITE
  },
  itemClassFilters: [],
  locationOptionCategory: "Department",
  readerLocationOptionCategory: "Department",
  additionalQueryAttributes: {
    itemType: ["image_path"],
    transferOrder: [],
    item: []
  },
  itemTypeQueryAttributes: [],
  epcTableHeaders: DEFAULT_EPC_TABLE_HEADERS,
  printOptions: {
    printType: "zpl",
    isPrintLaterEnabled: true
  },
  edgeApiUrl: "https://pgsjd2kyk1.execute-api.us-west-2.amazonaws.com/v1",
  autoStartScanning: false,
  readerOptions: {
    tagsTableApiUrl: "https://fm7jixcclb.execute-api.us-west-2.amazonaws.com/prod",
    tagsTableQueryFrequencyInSeconds: 3,
    tagsTableReadBufferMs: 2000,
    readMode: READ_MODE_OPTIONS.TAGS_TABLE,
    startReader: false,
    restartReader: false,
    pauseReader: false,
    startReaderOnSubmit: false,
    stopReaderOnSubmit: false,
    rssiFilter: {}
  },
  skipSubmission: false
};

export const useKioskConfigContext = () => {
  return useContext(KioskConfigContext);
};

export const KioskConfigContextProvider = ({ children }) => {
  const { actionId } = useKioskSearchParams();

  const kioskConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const configValues = useMemo(() => {
    const { config = {} } = kioskConfigProvider;
    const { actionsMap: newActionsMap = {} } = config;
    const actionConfig = newActionsMap[actionId] || {};

    const getConfigValue = (key) => {
      const { actionType } = actionConfig;
      return actionConfig[key] ?? initialState[actionType]?.[key] ?? config[key] ?? initialState[key];
    };

    const kioskConfig = Object.values(configKeys).reduce((acc, key) => {
      acc[key] = getConfigValue(key);
      return acc;
    }, {});

    // support legacy config where itemTypeQueryAttributes is a top level key
    const additionalQueryAttributes = kioskConfig[configKeys.ADDITIONAL_QUERY_ATTRIBUTES];
    const itemTypeQueryAttributes = kioskConfig[configKeys.ITEM_TYPE_QUERY_ATTRIBUTES];

    if (itemTypeQueryAttributes.length) {
      additionalQueryAttributes.itemType = itemTypeQueryAttributes;
    }

    kioskConfig.readerOptions = {
      ...initialState.readerOptions,
      ...kioskConfig.readerOptions
    };

    return {
      ...kioskConfig,
      action: actionConfig
    };
  }, [kioskConfigProvider, actionId]);

  return <KioskConfigContext.Provider value={configValues}>{children}</KioskConfigContext.Provider>;
};
